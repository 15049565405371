import InputMask from 'react-input-mask';

export const Input = ({name, placeholder = "", style, onChange, value, type, setShowPassword, icon, show, showPassword, disabled="", isPhone}) => {

    return (
        <div className='input__wrapper' style={style}>
            <div className='input__label'>{name}</div>
            <div className='img_wrapper'>
                <img className='input__img' src={icon} />
                {isPhone ?  <InputMask
                    mask="+38 (999) 999-99-99"
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                /> : <input style={disabled !== "" ? {backgroundColor: "#fff"} : {}} disabled={disabled} value={value} onChange={onChange} placeholder={placeholder} type={showPassword ? 'text' : 'password'} /> }
                { type && <img className='input__img' src={show} onMouseDown={() => setShowPassword(true)} onMouseUp={() => setShowPassword(false)} /> }
            </div>
        </div>
    )
}