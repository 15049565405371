import './style.css';
import { useEffect, useState, useRef } from 'react';
import people from '../../asset/img/people.svg';
import admin from '../../asset/img/admin.svg';
import deleteIcon from '../../asset/img/delete.svg';
import exit from '../../asset/img/exit.svg';
import userIcon from '../../asset/img/user.png';
import edit from '../../asset/img/edit.svg';
import add from '../../asset/img/add.svg';
import email from '../../asset/img/email.svg'
import close from '../../asset/img/close.svg'
import password from '../../asset/img/password.svg'
import { useNavigate } from 'react-router-dom';
import { Input } from '../../component/Input'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const User = ({ }) => {
    const [user, setUser] = useState([])
    const [filterUser, setFilterUser] = useState([])
    const [type, setType] = useState('user')
    const [search, setSearch] = useState('')
    const [showForm, setShowForm] = useState(false)
    const [showUserForm, setShowUserForm] = useState(false)
    const [addUserForm, setAddUserForm] = useState(false)
    const [addUser, setAddUser] = useState({
        email: '',
        password: '',
        repassword: ''
    })
    const [editUser, setEditUser] = useState({})


    const navigate = useNavigate();

    useEffect(() => {
        if (!localStorage.getItem('access_token')) {
            navigate('/');
        }
    }, [navigate]);

    useEffect(() => {
        fetch("https://api.viiapp.sofenty.com/user").then(data => data.json()).then(data => setUser(data))
    }, [])

    const getDate = (type) => {
        if (type === 'user') {
            fetch("https://api.viiapp.sofenty.com/user").then(data => data.json()).then(data => setUser(data))
        } else {
            fetch("https://api.viiapp.sofenty.com/admin").then(data => data.json()).then(data => setUser(data))
        }
    }

    const selectEditUser = (id) => {
        setEditUser(user.find(x => x._id === id))
        setShowUserForm(true)
    }

    const handleEditUser = async (id) => {
        const response = await fetch("https://api.viiapp.sofenty.com/admin/user/" + id, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(editUser)
        });
        const data = await response.json();

        let updatedUsers = user.map((u) => {
            if (u._id === id) {
                return { ...u, ...editUser };
            }
            return u;
        });


        setUser(updatedUsers);
        setShowUserForm(false);
    }

    const showNotification = (text) => {
        toast.error(text, {
            position: "bottom-right",
            closeOnClick: false,
            autoClose: 3000
        });
    };

    const handleExit = () => {
        localStorage.removeItem('access_token')
        navigate('/');
    }

    const login = async () => {
        if (addUser.password === addUser.repassword && addUser.password && addUser.email) {
            const response = await fetch("https://api.viiapp.sofenty.com/admin/add", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(addUser)
            });
            const data = await response.json();
            if (response.ok) {
                setUser([...user, { "_id": data._id, ...addUser }])
                setShowForm(false);
            } else {
                showNotification('Користувач з цією електронною адресою вже зареєстрований.')
            }
        } else if (!addUser.email) {
            showNotification('Електронна пошта не заповнена.')
        } else {
            showNotification('Пароль і підтвердження паролю не співпадають.')
        }
    }

    const handleAddUser = async () => {
        if (editUser.phone) {
            const response = await fetch("https://api.viiapp.sofenty.com/admin/user", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(editUser)
            });
            const data = await response.json();
            if (response.ok) {
                setUser([...user, { "_id": data._id, ...editUser }])
                setAddUserForm(false);
            } else {
                showNotification('Користувач з цім телефоном вже зареєстрований.')
            }
        } else if (!addUser.email) {
            showNotification('Телефон не заповнен.')
        }
    }

    const deleteAdmin = (id) => {
        fetch("https://api.viiapp.sofenty.com/admin/" + id, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Ошибка сети при удалении администратора');
                }
                return response.json();
            })
            .then(data => {
                setUser([...user.filter(x => x._id !== id)])
                console.log('Администратор удален:', data);
            })
            .catch(error => {
                console.error('Ошибка:', error);
            });
    };


    const deleteUser = (id) => {
        fetch("https://api.viiapp.sofenty.com/admin/user/" + id, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Ошибка сети при удалении администратора');
                }
                return response.json();
            })
            .then(data => {
                setUser([...user.filter(x => x._id !== id)])
                console.log('Администратор удален:', data);
            })
            .catch(error => {
                console.error('Ошибка:', error);
            });
    };

    const handleClickOutside = (event) => {
        if (event.target.classList.contains('form')) {
            setShowForm(false)
            setShowUserForm(false)
            setAddUserForm(false)
        }
    };

    useEffect(() => {
        if (type === 'user' && user.length > 0) {
            const data = user.filter(x => {
                const cleanPhone = x.phone?.replace(/[\s()+-]/g, '');
                if (cleanPhone) {
                    return cleanPhone?.includes(search.replace(/[\s()+-]/g, '')) || x._id.includes(search);
                }
                console.log(x);
                return x._id.includes(search);
            });
            setFilterUser(data)
        }
    }, [search, user])

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filterUser.slice(indexOfFirstItem, indexOfLastItem);

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(filterUser.length / itemsPerPage); i++) {
        pageNumbers.push(i);
    }



    return (
        <div className='user'>
            <div className='left'>
                <div className='top'>
                    <div onClick={() => { setType('user'); getDate('user') }} style={type === 'user' ? { backgroundColor: '#2F80EDaa', color: 'white', borderRadius: 14, padding: 15 } : { padding: 15, cursor: 'pointer' }}>
                        <img src={people} />
                        <div>Користувачі</div>
                    </div>
                    <div onClick={() => { setType('admin'); getDate('admin') }} style={type === 'admin' ? { backgroundColor: '#2F80EDaa', color: 'white', borderRadius: 14, padding: 15 } : { padding: 15, cursor: 'pointer' }}>
                        <img src={admin} />
                        <div>Адміністратори</div>
                    </div>
                    <div onClick={handleExit} style={{ padding: 15, cursor: 'pointer' }}>
                        <img src={exit} />
                        <div>Вихід</div>
                    </div>
                </div>
                <div className='top'>
                    <input placeholder='Пошук за телефоном або ID' onChange={e => setSearch(e.target.value)} value={search} />
                </div>
                <div className='bottom'>
                    <div className='logo'>Viiapp</div>
                    <div>Version: 1.0.0</div>
                </div>
            </div>
            <div className='right'>
                {type === 'user' && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>

                    <div onClick={() => { setAddUserForm(true); setEditUser({}) }} style={{ display: 'flex', cursor: 'pointer', alignItems: 'center', justifyContent: 'flex-end', marginBottom: 20, backgroundColor: '#2F80EDaa', borderRadius: 14, padding: 20, width: 220, float: 'right' }}>Додати Користувача <img style={{ width: 20, marginLeft: 10 }} src={add} /></div>
                    {currentItems.map((x, index) => (
                        <div key={x._id} style={{ display: 'flex', padding: "25px 20px", backgroundColor: '#fff', marginBottom: index === 9 ? 50 : 20, borderRadius: 14, width: "100%" }}>
                            <img style={{ width: 75, height: 75, borderRadius: '30%', marginRight: 25 }} src={x.avatar ?? userIcon} />
                            <div style={{ width: '100%' }}>
                                <div style={{ marginBottom: 5 }}>{x.name}</div>
                                <div style={{ marginBottom: 5 }}>{x.phone}</div>
                                <div style={{ marginBottom: 5 }}>{x.email}</div>
                                <div>{x.city ? x.city + ", " : ""}{x.address}</div>
                            </div>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                {x.specialization?.map(item => (
                                    <div key={item} style={{ fontSize: 12, textAlign: 'center', height: 25, padding: '0 10px', backgroundColor: '#8E8E93', borderRadius: 14, marginRight: 5, marginTop: 5, color: '#fff', display: 'flex', alignItems: 'center' }}>
                                        {item}
                                    </div>
                                ))}
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                <img style={{ width: 20 }} onClick={() => { setEditUser({}); selectEditUser(x._id); }} src={edit} />
                                <img style={{ width: 20 }} onClick={() => deleteUser(x._id)} src={deleteIcon} />
                            </div>
                        </div>
                    ))} </div>}
                {type === 'user' && <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingRight: 20, position: 'sticky', bottom: 16, right: 0, width: '100%', backgroundColor: '#fff' }}>
                    {pageNumbers.map((number, index) => (
                        <button key={number} onClick={() => setCurrentPage(number)} style={{ margin: 7, width: 40, backgroundColor: currentPage === index + 1 ? "#2F80EDaa" : "#fff", color: currentPage === index + 1 ? "#fff" : "#000", cursor: 'pointer' }}>
                            {number}
                        </button>
                    ))}
                </div>}
                {type === 'admin' && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>

                    <div onClick={() => setShowForm(true)} style={{ display: 'flex', cursor: 'pointer', alignItems: 'center', justifyContent: 'flex-end', marginBottom: 20, backgroundColor: '#2F80EDaa', borderRadius: 14, padding: 20, width: 200, float: 'right' }}>Add Administration <img style={{ width: 20, marginLeft: 10 }} src={add} /></div>
                    {user.map(x => {
                        return (
                            <div style={{ display: 'flex', padding: "25px 20px", backgroundColor: '#fff', marginBottom: 20, borderRadius: 14, width: '100%' }}>
                                <div style={{ width: '100%' }}>
                                    <div style={{ marginBottom: 5 }}>{x.email}</div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                    <img style={{ width: 20 }} onClick={() => deleteAdmin(x._id)} src={deleteIcon} />
                                </div>
                            </div>
                        )
                    })}
                </div>}
            </div>
            {showForm && <div onClick={e => handleClickOutside(e)} className='form' style={{ position: 'absolute', top: 0, left: 0, width: '100vw', height: '100vh', backgroundColor: '#0009', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <div onClick={(e) => e.stopPropagation()} style={{ backgroundColor: '#fff', width: '50%', height: '50%', borderRadius: 14, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Input name={'Електронна пошта'} style={{ width: '75%' }} icon={email} showPassword={true} onChange={e => setAddUser({ ...addUser, email: e.target.value })} value={addUser.email} placeholder={'Введіть вашу електронну адресу'} />
                    <Input name={'Пароль'} style={{ width: '75%' }} showPassword={true} icon={password} type={true} onChange={e => setAddUser({ ...addUser, password: e.target.value })} value={addUser.password} placeholder={'Введіть ваш пароль'} />
                    <Input name={'Підтвердження пароля'} style={{ width: '75%' }} showPassword={true} icon={password} type={true} onChange={e => setAddUser({ ...addUser, repassword: e.target.value })} value={addUser.repassword} placeholder={'Підтвердіть ваш пароль'} />
                    <button style={{ width: '75%' }} onClick={login}>Додати</button>
                </div>
            </div>}
            {showUserForm && <div onClick={e => handleClickOutside(e)} className='form' style={{ position: 'absolute', top: 0, left: 0, width: '100vw', height: '100vh', backgroundColor: '#0009', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <div onClick={(e) => e.stopPropagation()} style={{ backgroundColor: '#fff', width: '100%', maxWidth: 500, height: '80%', borderRadius: 14, display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', overflow: 'scroll', padding: '25px 0px' }}>
                    <img style={{ position: 'absolute', right: 15, top: 15 }} src={close} onClick={() => { setShowUserForm(false) }} />

                    <Input
                        name="ID"
                        style={{ width: '75%' }}
                        showPassword={true}
                        disabled={"disabled"}
                        value={editUser._id}
                    />

                    <Input
                        name="Телефон"
                        style={{ width: '75%' }}
                        showPassword={true}
                        disabled={"disabled"}
                        onChange={e => setEditUser({ ...editUser, phone: e.target.value })}
                        value={editUser.phone}
                    />

                    <Input
                        name="Електронна пошта"
                        style={{ width: '75%' }}
                        showPassword={true}
                        onChange={e => setEditUser({ ...editUser, email: e.target.value })}
                        value={editUser.email}
                    />

                    <Input
                        name="Ім'я"
                        style={{ width: '75%' }}
                        showPassword={true}
                        onChange={e => setEditUser({ ...editUser, name: e.target.value })}
                        value={editUser.name}
                    />

                    <Input
                        name="Місто"
                        style={{ width: '75%' }}
                        showPassword={true}
                        onChange={e => setEditUser({ ...editUser, city: e.target.value })}
                        value={editUser.city}
                    />

                    <Input
                        name="Адреса"
                        style={{ width: '75%' }}
                        showPassword={true}
                        onChange={e => setEditUser({ ...editUser, address: e.target.value })}
                        value={editUser.address}
                    />

                    <Input
                        name="Опис"
                        style={{ width: '75%' }}
                        showPassword={true}
                        onChange={e => setEditUser({ ...editUser, description: e.target.value })}
                        value={editUser.description}
                    />

                    <Input
                        name="Instagram"
                        style={{ width: '75%' }}
                        showPassword={true}
                        onChange={e => setEditUser({ ...editUser, instagram: e.target.value })}
                        value={editUser.instagram}
                    />

                    <Input
                        name="Деталі адреси"
                        style={{ width: '75%' }}
                        showPassword={true}
                        onChange={e => setEditUser({ ...editUser, address_details: e.target.value })}
                        value={editUser.address_details}
                    />
                    <button style={{ width: '75%' }} onClick={() => handleEditUser(editUser._id)}>Оновити</button>
                </div>
            </div>}
            {addUserForm && <div onClick={e => handleClickOutside(e)} className='form' style={{ position: 'absolute', top: 0, left: 0, width: '100vw', height: '100vh', backgroundColor: '#0009', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <div onClick={(e) => e.stopPropagation()} style={{ backgroundColor: '#fff', width: '100%', maxWidth: 500, borderRadius: 14, display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', overflow: 'scroll', padding: '25px 0px' }}>
                    <img style={{ position: 'absolute', right: 15, top: 15 }} src={close} onClick={() => { setAddUserForm(false) }} />
                    <Input
                        name="Телефон"
                        style={{ width: '75%' }}
                        showPassword={true}
                        onChange={e => setEditUser({ ...editUser, phone: e.target.value })}
                        value={editUser.phone}
                        isPhone={true}
                    />
                    <button style={{ width: '75%' }} onClick={handleAddUser}>Додати</button>
                </div>
            </div>}
            <ToastContainer closeButton={false} />
        </div>
    )
}